import './module.error.navigable.css';

require('../../lib/v3/bootstrap-select/1.13.2/js/bootstrap-select.min.js');

import Navbar from '../../js/app/common/navbar';
window.Navbar = Navbar;

import ErrorController from '../../js/app/common/error-controller';
window.ErrorController = ErrorController;

$(document).ready(function() {
	window.controller = new ErrorController();
});