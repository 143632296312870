export default class ErrorController {
	
	constructor() {
		this.storageName = 'quantor_reservation_desktop';
        this.reservation = JSON.parse(localStorage.getItem(this.storageName));
        this.navbar = new Navbar();
    }
	
	close() {
		var city = this.reservation.city;
		var voivodeship = this.reservation.voivodeship;
		var symbol = this.reservation.symbol;
				
		var url = PATH + '/kantory/';
		url += city === 'false' ? voivodeship : city;
		url += symbol.length > 0 ? ',' + symbol : '';
				
		window.location = url + '.html';
	}
	
}