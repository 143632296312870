export default class Navbar{
    
    constructor(callback) {
    	this.callback = callback;
    	// $('[data-toggle="tooltip"]').tooltip();
    	this._loadLanguages();
    }
    
    languageChanged() {
    	var lang = $('#language-select').val()
		var path = location.pathname.split(LOCALE);
		var url = '';
    	if(lang.toUpperCase() === 'PL') {
			url = '.';
    	} else {
			url = './' + lang;
    	}
		if (typeof path[1] !== 'undefined') {
			url += path[1];
		}
		if (typeof location.search !== 'undefined') {
			url += location.search;
		}
		window.location = url;
    }
    
    _loadLanguages() {
        this.languages = [];
        var controller = this;
        
        XHR.get({
			url: './languages.json',
	        data: {},
	        success : function(response) {
	        	response = JSON.parse(response);
	        	if(Array.isArray(response) && response.length > 0 ) {
	                $.each(response, function (i, language) {
	                    controller.languages.push(language);
	                    
	                    var attributes = { 
	                        	value: language.toLowerCase(),
	                        	'data-content': 
	                        		"<span>"
	                        		+ "<img src='./build/images/flags/" + language + ".png'> "
	                        		+ language.toUpperCase()
	                        		+ "</span>"
	                        		
	                        };
	                    
	                    $('#language-select').append($('<option>', attributes));
	                });
	            }
	        	
	        	$.fn.selectpicker.Constructor.BootstrapVersion = '4';
	            $.fn.selectpicker.Constructor.DEFAULTS.size = 2;
	            $.fn.selectpicker.Constructor.DEFAULTS.title = '';
	            $.fn.selectpicker.Constructor.DEFAULTS.liveSearch = false;
	            
	            $('#language-select').val(LOCALE.toLowerCase());
	            
	            $('#language-select').selectpicker()
	            	.selectpicker('setStyle', 'btn-light')
	            	.css('display', 'block');
	        }
		});
    }
    
    
}